@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: border-box;
}
